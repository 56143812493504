import PropTypes from "prop-types";

const ButtonStylesShape = PropTypes.shape({
  color: PropTypes.string,
  border: PropTypes.string,
  bgColor: PropTypes.string,
  bgColorHover: PropTypes.string,
  borderWidth: PropTypes.number
});

const ButtonClassesShape = PropTypes.shape({
  button: PropTypes.string
});

export const ButtonPropTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  // TODO: use only classes || customClasses not both
  classes: ButtonClassesShape,
  copy: PropTypes.string,
  styles: ButtonStylesShape,
  tag: PropTypes.oneOf(["button", "a", "link"])
};

export const GridPropTypes = PropTypes.shape({
  phone: PropTypes.number,
  mobile: PropTypes.number,
  desktop: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
  xxxl: PropTypes.number
});

export const RecommendationPropTypes = PropTypes.shape({
  recommendation_type: PropTypes.string,
  recommendation_uuid: PropTypes.string,
  recommendationable_type: PropTypes.string,
  recommendationable_id: PropTypes.string,
  recommender_type: PropTypes.string,
  recommender_id: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string
});

export const SlidePropTypes = PropTypes.shape({
  header: PropTypes.string,
  image: PropTypes.string,
  subheader: PropTypes.string,
  tertiary: PropTypes.string,
  title: PropTypes.string
});

export const OnboardingPropTypes = PropTypes.shape({
  service_user_uid: PropTypes.string,
  steps: PropTypes.array,
  created_at: PropTypes.string,
  sections: PropTypes.array,
  user_id: PropTypes.string,
  status: PropTypes.string,
  service: PropTypes.string,
  updated_at: PropTypes.string,
  id: PropTypes.string,
  category: PropTypes.string,
  required: PropTypes.bool,
  config: PropTypes.shape({
    component: PropTypes.string,
    step: PropTypes.string,
    content: PropTypes.shape({
      slides: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
          title: PropTypes.string,
          header: PropTypes.string,
          subheader: PropTypes.string,
          tertiary: PropTypes.string
        })
      ),
      action: PropTypes.string
    })
  })
});

export const ViewPropTypes = {
  uuid: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  computeView: PropTypes.func,
  updateView: PropTypes.func,
  canCreate: PropTypes.bool,
  display: PropTypes.shape({
    analysis: PropTypes.oneOf(["pivot", "linear"]),
    presentation: PropTypes.oneOf(["accumulative", "cumulative"]),
    chart: PropTypes.oneOf(["number", "line", "bar", "table"]),
    pivot: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.string),
      stacks: PropTypes.object
    })
  })
};

export const LayoutPropTypes = {
  uuid: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export const DashboardPropTypes = {
  uuid: PropTypes.string,
  title: PropTypes.string,
  onDragEnd: PropTypes.func.isRequired,
  computeView: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
  updateDashboardViewLayout: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.shape(ViewPropTypes)),
  layouts: PropTypes.arrayOf(PropTypes.shape(LayoutPropTypes))
};

export const DashboardMiniPropTypes = {
  uuid: PropTypes.string,
  title: PropTypes.string,
  computeView: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
  updateDashboardViewLayout: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.shape(ViewPropTypes)),
  layouts: PropTypes.arrayOf(PropTypes.shape(LayoutPropTypes))
};

export const PaymentMethodPropTypes = {
  payment_method_id: PropTypes.string,
  fingerprint: PropTypes.string,
  brand: PropTypes.string,
  organization_id: PropTypes.string,
  created_at: PropTypes.string,
  is_default: PropTypes.bool,
  status: PropTypes.string,
  updated_at: PropTypes.string,
  id: PropTypes.string,
  uuid: PropTypes.string,
  customer_id: PropTypes.string,
  billing_details: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.number,
    address: PropTypes.shape({
      city: PropTypes.string,
      line1: PropTypes.string,
      line2: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      postal_code: PropTypes.string
    })
  }),
  exp_month: PropTypes.number,
  exp_year: PropTypes.number
};

export const ComputationPropTypes = {
  title: PropTypes.string,
  computedAt: PropTypes.string,
  display: PropTypes.shape({
    capabilities: PropTypes.shape({
      analysis: PropTypes.arrayOf(PropTypes.string),
      presentation: PropTypes.arrayOf(PropTypes.string),
      chart: PropTypes.arrayOf(PropTypes.string)
    }),
    defaults: PropTypes.shape({
      analysis: PropTypes.string,
      presentation: PropTypes.string,
      chart: PropTypes.string
    })
  }),
  meta: PropTypes.shape({
    meta: PropTypes.shape({
      meta: PropTypes.string,
      count: PropTypes.string
    })
  }),
  data: PropTypes.array
};

export const EntityPropTypes = {
  entity: PropTypes.object,
  afterChange: PropTypes.func,
  stateKey: PropTypes.string,
  name: PropTypes.string
};

export const ProductActionType = {
  copy: PropTypes.oneOfType([
    PropTypes.shape({
      template: PropTypes.string,
      value: PropTypes.string
    }),
    PropTypes.string
  ]),
  href: PropTypes.oneOfType([
    PropTypes.shape({
      template: PropTypes.string,
      value: PropTypes.string
    }),
    PropTypes.string
  ]),
  query: PropTypes.object,
  action_uid: PropTypes.string,
  tag: PropTypes.oneOf(["button", "a", "link"])
};

export const ProductFeatureType = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      title: PropTypes.string,
      uuid: PropTypes.string
    })
  )
};

export const EditorFocusType = {
  key: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const SchemaPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  properties: PropTypes.object.isRequired
};

export const ApplicationPropTypes = {
  subdomain: PropTypes.string.isRequired,
  merchant_account_id: PropTypes.string.isRequired,
  routes: PropTypes.object,
  theme_id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string
};

export const LivePropTypes = {
  weight: PropTypes.number.isRequired,
  manifest: PropTypes.string.isRequired,
  deployment: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
};

export const SelectFieldPropTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ).isRequired,
  errorLabel: PropTypes.string,
  customClasses: PropTypes.object
};

export const ShortLinkPropTypes = {
  created_at: PropTypes.string,
  data: PropTypes.shape({
    action_uid: PropTypes.string,
    application_id: PropTypes.string,
    cancel_url: PropTypes.string,
    merchant_account_id: PropTypes.string,
    product: PropTypes.shape({
      checkout: PropTypes.shape({
        payment_method_types: PropTypes.arrayOf(PropTypes.string),
        allow_promotion_codes: PropTypes.bool,
        discounts: PropTypes.arrayOf(
          PropTypes.shape({
            coupon: PropTypes.string,
            promotion_code: PropTypes.string
          })
        ),
        billing_address_collection: PropTypes.string,
        shipping_address_collection: PropTypes.shape({
          allowed_countries: PropTypes.arrayOf(PropTypes.string)
        }),
        submit_type: PropTypes.string,
        tax_rates: PropTypes.arrayOf(PropTypes.string),
        dynamic_tax_rates: PropTypes.arrayOf(PropTypes.string)
      }),
      primaries: PropTypes.arrayOf(
        PropTypes.shape({
          product: PropTypes.string,
          currency: PropTypes.string,
          interval: PropTypes.string,
          price: PropTypes.string,
          quantity: PropTypes.number,
          // https://stripe.com/docs/payments/checkout/taxes
          // Only allowed in subscription mode currently
          tax_rates: PropTypes.arrayOf(PropTypes.string),
          dynamic_tax_rates: PropTypes.arrayOf(PropTypes.string)
        })
      )
    }),
    success_url: PropTypes.string
  }),
  description: PropTypes.string,
  expiration: PropTypes.number,
  id: PropTypes.string,
  short_id: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string,
  updated_at: PropTypes.string,
  uuid: PropTypes.string
};

export const TaxRatePropTypes = {
  id: PropTypes.string,
  display_name: PropTypes.string,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inclusive: PropTypes.bool,
  description: PropTypes.string,
  jurisdiction: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string
};

export const ShippingRatePropTypes = {
  id: PropTypes.string,
  display_name: PropTypes.string,
  costs: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
      display_name: PropTypes.string
    })
  )
};

export const ManifestOptionPropTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  previewUrl: PropTypes.string
};

export const AttachmentPropTypes = {
  id: PropTypes.string,
  uuid: PropTypes.string,
  data: PropTypes.object
};

export const SegmentedControlPropTypes = {
  options: PropTypes.array,
  rowCount: PropTypes.number,
  maxWidth: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool
  ]),
  theme: PropTypes.string,
  onClick: PropTypes.func
};
