import React from "react";
import PropTypes from "prop-types";
import { getSpinnerStyles } from "./spinner";

export const DEFAULT_CLASSES = {
  ring: "white",
  size: "regular"
};

const BASE_CLASS = "priceblocs--loadingRing";

const LoadingSpinner = ({ customClasses, small, standard }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const { styles: spinnerStyles } = getSpinnerStyles({ small, standard });

  return (
    <div className={`v-mid ${BASE_CLASS} ${BASE_CLASS}-${classes.size}`}>
      <div className={`${BASE_CLASS}-${classes.ring}`} />
      <div className={`${BASE_CLASS}-${classes.ring}`} />
      <div className={`${BASE_CLASS}-${classes.ring}`} />
      <div className={`${BASE_CLASS}-${classes.ring}`} />
      {spinnerStyles}
    </div>
  );
};

LoadingSpinner.propTypes = {
  customClasses: PropTypes.shape({
    ring: PropTypes.oneOf(["white", "blue", "gold"]),
    size: PropTypes.oneOf(["regular", "small"])
  })
};

export default LoadingSpinner;
