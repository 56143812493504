import React, { Fragment } from "react";
import { ButtonPropTypes } from "utils/propTypes";
import Link from "next/link";
import LoadingSpinner from "components/LoadingSpinner";
import omit from "lodash/omit";
import merge from "lodash/merge";
import { DURATION } from "utils/styles";
import classnames from "classnames";
import {
  TA_MAN,
  BUTTON_THEME,
  BUTTON_CLASS_CONTEXT,
  BUTTON_TAGS
} from "utils/constants/ui";
import { reduceStyleOverrides } from "utils/view";

export const FULL_WIDTH_BUTTON =
  "fw6 br2 ph3 pv2 border-box input-reset ba bg-transparent pointer f6 dib link items-center flex flex-shrink-0 w-100";

export const DEFAULT_CLASSES = {
  contextClass: BUTTON_CLASS_CONTEXT.PRIMARY,
  button:
    "fw6 br2 ph3 pv2 border-box input-reset ba bg-transparent pointer f6 dib link items-center flex flex-shrink-0"
};

const DEFAULT_STYLES = {
  height: 44,
  borderWidth: 1,
  loading: {
    dotClass: "dot-white",
    ringClass: "white",
    sizeClass: "regular"
  }
};

const Button = ({
  tag,
  type,
  loading,
  disabled,
  classes,
  copy,
  onClick,
  styles,
  theme,
  children,
  ...props
}) => {
  const compClasses = merge({}, DEFAULT_CLASSES, classes);
  const compStyles = merge({}, DEFAULT_STYLES, BUTTON_THEME[theme], styles);

  const elClassName = classnames(
    `${compClasses.contextClass} ${compClasses.button} ${TA_MAN}`,
    {
      "cursor-not-allowed": disabled || loading,
      [`disabled-link-${compClasses.contextClass}`]:
        tag === BUTTON_TAGS.ANCHOR && disabled
    }
  );

  const elProps = {
    ...props,
    type,
    disabled: disabled && !loading,
    className: elClassName,
    rel: "noopener noreferrer"
  };
  if (onClick && !disabled && !loading) {
    elProps.onClick = (evt) => {
      // Allow default for a tags which are opening a new tab
      // Should be passing evt here?
      onClick();
      if (tag !== BUTTON_TAGS.ANCHOR) {
        evt.preventDefault();
      }
    };
  }

  const copyComp = (
    <div className="flex h-100 items-center justify-center w-100">
      <div className="dib self-center user-select-n">
        {loading ? (
          <LoadingSpinner
            customClasses={{
              size: compStyles.loading.sizeClass
            }}
          />
        ) : (
          children || copy
        )}
      </div>
    </div>
  );
  const omitKeys = ["iconType", "customClasses"];
  if (tag !== BUTTON_TAGS.ANCHOR || (tag === BUTTON_TAGS.ANCHOR && disabled)) {
    omitKeys.push("href");
  }
  const trimmed = omit(elProps, omitKeys);

  return (
    <Fragment>
      {tag === BUTTON_TAGS.ANCHOR ? (
        <a {...trimmed}>{copyComp}</a>
      ) : tag === BUTTON_TAGS.LINK ? (
        <Link href={elProps.href}>
          <a {...trimmed}>{copyComp}</a>
        </Link>
      ) : (
        <button type={BUTTON_TAGS.BUTTON} {...trimmed}>
          {copyComp}
        </button>
      )}
      <style jsx>{`
        button,
        a {
          outline: none;
          user-select: none;
          height: ${compStyles.height}px;
          border-width: ${compStyles.borderWidth}px;
          -webkit-transition: background-color ${DURATION}s,
            -webkit-transform ${DURATION}s;
          -webkit-transition: border-color ${DURATION}s,
            -webkit-transform ${DURATION}s;
          transition: background-color ${DURATION}s, transform ${DURATION}s;
          transition: border-color ${DURATION}s, transform ${DURATION}s;
          ${reduceStyleOverrides(compStyles)}
        }
      `}</style>
    </Fragment>
  );
};

Button.propTypes = ButtonPropTypes;

Button.defaultProps = {
  tag: BUTTON_TAGS.BUTTON
};

export default Button;
