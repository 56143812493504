import css from "styled-jsx/css";
import { COLORS } from "utils/styles";

export const getSpinnerStyles = (props) => {
  const standardSize = props.standard || 22;
  const smallSize = props.small || 18;

  return css.resolve`
    :global(.priceblocs--loadingRing) {
      display: inline-block;
      position: relative;
      width: ${standardSize}px;
      height: ${standardSize}px;
    }
    :global(.priceblocs--loadingRing div) {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: ${smallSize}px;
      height: ${smallSize}px;
      margin: 2px;
      border-radius: 50%;
      animation: priceblocs--loadingRing 1.2s cubic-bezier(0.5, 0, 0.5, 1)
        infinite;
    }
    :global(.priceblocs--loadingRing-small) {
      display: inline-block;
      position: relative;
      width: ${smallSize}px;
      height: ${smallSize}px;
    }
    :global(.priceblocs--loadingRing-small div) {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      margin: 2px 1px 1px 1px;
      border-radius: 50%;
      animation: priceblocs--loadingRing 1.2s cubic-bezier(0.5, 0, 0.5, 1)
        infinite;
    }
    :global(.priceblocs--loadingRing-blue) {
      border: 2px solid ${COLORS.blue};
      border-color: ${COLORS.blue} transparent transparent transparent;
    }
    :global(.priceblocs--loadingRing-white) {
      border: 2px solid currentColor;
      border-color: currentColor transparent transparent transparent;
    }
    :global(.priceblocs--loadingRing-gold) {
      border: 2px solid currentColor;
      border-color: currentColor transparent transparent transparent;
    }
    :global(.priceblocs--loadingRing div:nth-child(1)) {
      animation-delay: -0.45s;
    }
    :global(.priceblocs--loadingRing div:nth-child(2)) {
      animation-delay: -0.3s;
    }
    :global(.priceblocs--loadingRing div:nth-child(3)) {
      animation-delay: -0.15s;
    }
    @keyframes priceblocs--loadingRing {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
};
